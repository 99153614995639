import React, { Component } from "react"
import Nav from "../components/nav/nav"
import Seo from "../components/seo/seo"
import GlofoxMemberships from "../components/glofox/memberships/memberships"

class Memberships extends Component {
  render() {
    return (
      <Seo title={'Memberships'}>
          <GlofoxMemberships />
          <Nav showLogo={true} isDarkBackground={true} asFab={true} />
      </Seo>
    )
  }
}

export default Memberships;
