import React from "react"
import * as styles from "./memberships.module.css"


function GlofoxMemberships() {
    let glofox_membership_snippet = '<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js"></script> <iframe id="glofox_2" src="https://app.glofox.com/portal/#/branch/5f75b06a11604326e112dbac/memberships?header=memberships" width="100%" height="100%" scrolling="yes" frameborder="0"></iframe><div style="display:flex; color: white; padding=16px; justify-content:center; text-decoration:none;font-family: Arial, Helvetica, sans-serif;">powered by<a style="text-decoration: none; font-family: Arial, Helvetica, sans-serif; "href="https://www.glofox.com"><b>&nbsp;Glofox</b></a></div><script> {iFrameResize({ log: false, checkOrigin: false,tolerance: 10,sizeHeight: true,heightCalculationMethod: \'lowestElement\',minHeight: 1000,scrolling: \'auto\'},\'#glofox_2\')}</script>'

    return (
        <section className={styles.wrapper} dangerouslySetInnerHTML={{__html: glofox_membership_snippet}} />
    )
}

export default GlofoxMemberships